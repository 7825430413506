const ProductionConstants = {
    API_URL: 'https://api.soar.earth',
    DOMAIN: 'picsfromspace.soar.earth',
    STRIPE_API_KEY: 'pk_live_JH4z4tzI3Ge7RAGkipc9XpFu',
    SENTRY_URL: 'https://709845406a9242e9b968f0b4404b0926@o374708.ingest.sentry.io/4505368021368832',
    GOOGLE_ANALYTICS_ID_G4: 'G-8HCBXTFY4R',
    GTAG_ID: 'GTM-KRJNJQ6',
    ENV: 'production',
    CLARITY_ID: 'hkolleg7sp',
    COGNITO_REGION: 'ap-southeast-1',
    COGNITO_CLIENT_ID: '6tscp1cradeg0pl1ep1uf71dfn',
};

const DevelopmentConstants = {
    API_URL: 'https://api.soar-test.earth',
    DOMAIN: 'picsfromspace.soar-test.earth',
    STRIPE_API_KEY: 'pk_test_974MTyw0A8HbjXO2kpTL8i8b',
    SENTRY_URL: 'https://515db29d1fad451d8f19ce6b8df5770e@o374708.ingest.sentry.io/4505367779147776',
    GOOGLE_ANALYTICS_ID_G4: 'G-3NC3FHYFTL',
    GTAG_ID: 'GTM-M8KZLX2',
    ENV: 'development',
    CLARITY_ID: 'hkoky8n5yl',
    COGNITO_REGION: 'ap-southeast-1',
    COGNITO_CLIENT_ID: '1f0ehn6akgq9klllukqlphtch8',
};

const CURRENT_YEAR = new Date().getFullYear().toString();

const LANDSAT_RESOLUTION = 30;

const UniversalConstants = {
    GEOCODE_API: '46b928a0-02e5-11eb-b5cb-59f89b880c29',
    GOOGLE_GEOCODER_API: 'AIzaSyDIHfiYwIdK0Lclybj1_Y9LaG4vt3moT2g',
    AVATAR_URL: 'https://avatar.soar.earth',
    GOOGLE_FORM_TEMPLATE_URL:
        'https://docs.google.com/forms/d/e/1FAIpQLScOcPW0WZRTGya-ML9LuO9P-ZvFrf-89eiU1FZvpaEE7V4v7g/viewform?usp=pp_url&entry.1701245881=fileid&entry.181656169=subdomain',
    MAPS_ATTRIBUTION: {
        ESRI_ATTRIBUTION:
            'Powered by <a style="color:inherit;" target="_blank" rel="noopener noreferrer" href="http://www.esri.com">Esri</a> | HERE, Garmin, FAO, NOAA, USGS, © OpenStreetMap contributors, and the GIS User Community',
        ESRI_MOBILE_ATTRIBUTION: 'Powered by Esri',
        GOOGLE_ATTRIBUTION: `Map data &copy;${CURRENT_YEAR} Google`,
        GOOGLE_MOBILE_ATTRIBUTION: '© Google',
        OSM_ATTRIBUTION:
            '&copy; <a target="_blank" rel="noopener noreferrer" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        OSM_MOBILE_ATTRIBUTION: '© OpenStreetMap',
        OSM_TOPO_ATTRIBUTION:
            '&copy; <a target="_blank" rel="noopener noreferrer" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style &copy; <a target="_blank" rel="noopener noreferrer" href="https://opentopomap.org/ ">OpenTopoMap</a> (<a target="_blank" rel="noopener noreferrer" href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        OSM_TOPO_MOBILE_ATTRIBUTION: '© OpenStreetMap contributors | Map style © OpenTopoMap',
    },
    MAPS: {
        DARK_GRAY_BASE_MAP:
            'https://server.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}.png',
        OSM_TOPO: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
        OSM_STREET: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        OSM_HILLSHADE: 'https://tiles.wmflabs.org/hillshading/{z}/{x}/{y}.png',
        MAPBOX_SATELLITE:
            'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiY2hyaXNsb3dlIiwiYSI6ImYzZmI2OWM3MDgyNWQ0MTdmZTRkYWM5MjVlN2JlY2Q3In0.3aXTSJOkgeC_obQ6TDmlcw',
        GOOGLE_SATELLITE: 'https://mt.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
        GOOGLE_STREET: 'https://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        GOOGLE_HYBRID: 'https://mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        MODIS_TEMPLATE:
            'https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/MODIS_Terra_CorrectedReflectance_TrueColor/default/{date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg',
        HIMAWARI: 'http://d30f2i1nzx9v71.cloudfront.net/himawari/IDE00436/Himawari/{date}/{z}/{x}/{-y}.jpg',
    },
    SENTINEL: {
        LANDSAT_8_STATISTICAL_API:
            'https://services-uswest2.sentinel-hub.com/ogc/fis/11ce5e0b-7a4d-4942-82d0-e314eead484a?LAYER=1_TRUE_COLOR&TIME=${date}&BBOX=${bbox}&RESOLUTION=200&MAXCC=100&crs=EPSG:3857&name=Landsat%208%20-%20L1C',
        LANDSAT_8_TRUE_COLOR:
            'https://services-uswest2.sentinel-hub.com/ogc/wms/11ce5e0b-7a4d-4942-82d0-e314eead484a?service=WMS&request=GetMap&layers=1_TRUE_COLOR&format=image%2Fpng&transparent=true&version=1.1.1&showlogo=false&name=Landsat%208%20-%20L1C&resX=${resX}&resY=${resY}&maxcc=100&time=${date}%2F${date}&bbox=${bbox}&crs=EPSG:3857',
        SENTINEL_1_STATISTICAL_API:
            'https://services.sentinel-hub.com/ogc/fis/28b4c9f0-28ea-4d19-8bb7-1bfc9f18c9bc?LAYER=SENTINEL_1_GRD&TIME=${date}&BBOX=${bbox}&RESOLUTION=200&MAXCC=100&crs=EPSG:3857',
        SENTINEL_1_RADAR:
            'https://services.sentinel-hub.com/ogc/wms/28b4c9f0-28ea-4d19-8bb7-1bfc9f18c9bc?service=WMS&request=GetMap&layers=SENTINEL_1_GRD&format=image%2Fpng&transparent=true&version=1.1.1&showlogo=false&name=Sentinel-2%20L1C&resX=${resX}&resY=${resY}&maxcc=100&time=${date}%2F${date}&bbox=${bbox}&crs=EPSG:3857',
        SENTINEL_2_STATISTICAL_API:
            'https://services.sentinel-hub.com/ogc/fis/28b4c9f0-28ea-4d19-8bb7-1bfc9f18c9bc?LAYER=CLOUD-STATISTICS-FIS&TIME=${date}&BBOX=${bbox}&RESOLUTION=200&MAXCC=100&crs=EPSG:3857',
        SENTINEL_2_TRUE_COLOR:
            'https://services.sentinel-hub.com/ogc/wms/28b4c9f0-28ea-4d19-8bb7-1bfc9f18c9bc?service=WMS&request=GetMap&layers=TRUE_COLOR_PREVIEW&format=image%2Fpng&transparent=true&version=1.1.1&showlogo=false&name=Sentinel-2%20L1C&resX=${resX}&resY=${resY}&maxcc=100&time=${date}%2F${date}&bbox=${bbox}&crs=EPSG:3857',
        SENTINEL_2_FALSE_COLOR:
            'https://services.sentinel-hub.com/ogc/wms/28b4c9f0-28ea-4d19-8bb7-1bfc9f18c9bc?service=WMS&request=GetMap&layers=FALSE_COLOR&format=image%2Fpng&transparent=true&version=1.1.1&showlogo=false&name=Sentinel-2%20L1C&resX=${resX}&resY=${resY}&maxcc=100&time=${date}%2F${date}&bbox=${bbox}&crs=EPSG:3857',
        SENTINEL_2_AGRICULTURE:
            'https://services.sentinel-hub.com/ogc/wms/28b4c9f0-28ea-4d19-8bb7-1bfc9f18c9bc?service=WMS&request=GetMap&layers=AGRICULTURE&format=image%2Fpng&transparent=true&version=1.1.1&showlogo=false&name=Sentinel-2%20L1C&resX=${resX}&resY=${resY}&maxcc=100&time=${date}%2F${date}&&bbox=${bbox}&crs=EPSG:3857',
        SENTINEL_2_NDVI:
            'https://services.sentinel-hub.com/ogc/wms/28b4c9f0-28ea-4d19-8bb7-1bfc9f18c9bc?service=WMS&request=GetMap&layers=NDVI&format=image%2Fpng&transparent=true&version=1.1.1&showlogo=false&name=Sentinel-2%20L1C&resX=${resX}&resY=${resY}&maxcc=100&time=${date}%2F${date}&bbox=${bbox}&crs=EPSG:3857',
        SENTINEL_2_GEOLOGY:
            'https://services.sentinel-hub.com/ogc/wms/28b4c9f0-28ea-4d19-8bb7-1bfc9f18c9bc?service=WMS&request=GetMap&layers=GEOLOGY&format=image%2Fpng&transparent=true&version=1.1.1&showlogo=false&name=Sentinel-2%20L1C&resX=${resX}&resY=${resY}&maxcc=100&time=${date}%2F${date}&bbox=${bbox}&crs=EPSG:3857',
        SENTINEL_2_DEM:
            'https://services-uswest2.sentinel-hub.com/ogc/wms/a0f68052-61a1-412e-984d-33932cacb225?service=WMS&request=GetMap&layers=DEM___COLOR&format=image%2Fpng&transparent=true&version=1.1.1&showlogo=false&name=Sentinel-2%20L1C&resX=${resX}&resY=${resY}&maxcc=100&time=${date}%2F${date}&bbox=${bbox}&crs=EPSG:3857',
    },
    OVERLAY_DATA: {
        SENTINEL: {
            TRUE_COLOR: {
                class: 'Natural Colour',
                satellite: 'Sentinel 2',
                satelliteAnalytics: 'Sentinel-2 L1C',
                description: '10m per pixel',
                key: 'TRUE_COLOR',
                image: '/assets/overlay-preview/true-color-overlay-preview.jpg',
            },
            FALSE_COLOR: {
                class: 'False Colour',
                satellite: 'Sentinel 2',
                satelliteAnalytics: 'Sentinel-2 L1C',
                description: 'Shows the infrared spectrum',
                key: 'FALSE_COLOR',
                image: '/assets/overlay-preview/false-color-overlay-preview.jpg',
            },
            GEOLOGY: {
                class: 'Geology',
                satellite: 'Sentinel 2',
                satelliteAnalytics: 'Sentinel-2 L1C',
                description: 'Highlights geological features',
                key: 'GEOLOGY',
                image: '/assets/overlay-preview/geology-overlay-preview.jpg',
            },
            NDVI: {
                class: 'NDVI',
                satellite: 'Sentinel 2',
                satelliteAnalytics: 'Sentinel-2 L1C',
                description: 'Measures vegetation health',
                key: 'NDVI',
                image: '/assets/overlay-preview/ndvi-overlay-preview.jpg',
            },
            SENTINEL_1_RADAR: {
                class: 'Radar',
                satellite: 'Sentinel 1',
                satelliteAnalytics: 'Sentinel-1 GRD',
                description: 'Cloud penetrating Radar',
                key: 'SENTINEL_1_RADAR',
                image: '/assets/overlay-preview/radar-overlay-preview.png',
            },
        },
        LANDSAT: {
            LANDSAT_8: {
                class: 'Natural Colour',
                satellite: 'Landsat 8',
                satelliteAnalytics: 'Landsat-8 L1C',
                description: `${LANDSAT_RESOLUTION}m per pixel`,
                key: 'LANDSAT_8',
                image: '/assets/overlay-preview/landsat-8-true-color-preview.png',
                resolution: LANDSAT_RESOLUTION,
            },
        },
    },
    SATELLITE_TLE: {
        SKYMAP50_1_01_TLE1: '1 41907U 16083A   20068.84877354  .00000587  00000-0  35231-4 0  9999',
        SKYMAP50_1_01_TLE2: '2 41907  97.5257 154.6517 0014067 216.9429 261.5445 15.14715373176545',

        SKYMAP50_1_02_TLE1: '1 41908U 16083B   20068.81991626  .00000627  00000-0  37315-4 0  9996',
        SKYMAP50_1_02_TLE2: '2 41908  97.4570 145.8906 0013055 214.8291 261.1316 15.14724842176541',

        SKYMAP50_1_03_TLE1: '1 43099U 18002A   20068.80589639  .00000444  00000-0  27509-4 0  9999',
        SKYMAP50_1_03_TLE2: '2 43099  97.5105 149.2480 0007878 108.9200   6.9830 15.14743744119492',

        SKYMAP50_1_04_TLE1: '1 43100U 18002B   20068.83522944  .00000453  00000-0  27969-4 0  9991',
        SKYMAP50_1_04_TLE2: '2 43100  97.5109 149.4097 0014421 100.2969  17.5691 15.14740383119505',

        LANDSAT8_TLE1: '1 39084U 13008A   20069.75913015  .00000021  00000-0  14749-4 0  9996',
        LANDSAT8_TLE2: '2 39084  98.1922 140.9589 0001549  95.6530 264.4844 14.57119486376183',

        SENTINEL_1A_TLE1: '1 39634U 14016A   20069.80746796 -.00000011  00000-0  74628-5 0  9996',
        SENTINEL_1A_TLE2: '2 39634  98.1820  78.2024 0001463  89.3980 270.7392 14.59198562316032',

        SENTINEL_2A_TLE1: '1 40697U 15028A   20069.75292253 -.00000020  00000-0  89644-5 0  9993',
        SENTINEL_2A_TLE2: '2 40697  98.5682 145.3979 0001373  91.7182 268.4153 14.30816507246204',

        SETNINEL_3A_TLE1: '1 41335U 16011A   20069.63447160 -.00000009  00000-0  14466-4 0  9998',
        SENTINEL_3A_TLE2: '2 41335  98.6192 137.7916 0001415 103.1404 256.9934 14.26739043211440',

        SENTINEL_1B_TLE1: '1 41456U 16025A   20069.90984071 -.00000029  00000-0  35666-5 0  9992',
        SETNINEL_1B_TLE2: '2 41456  98.1818  78.1284 0001454  86.6454 273.4920 14.59198326206210',

        SENTINEL_2B_TLE1: '1 42063U 17013A   20069.78790686 -.00000006  00000-0  14266-4 0  9995',
        SENTINEL_2B_TLE2: '2 42063  98.5688 145.4384 0001477  97.9244 262.2102 14.30816884157128',

        SENTINEL_5P_TLE1: '1 42969U 17064A   20069.37569438 -.00000026  00000-0  85723-5 0  9999',
        SENTINEL_5P_TLE2: '2 42969  98.7271   9.9060 0001017  84.2357 275.8936 14.19550222124556',

        SENTINEL_3B_TLE1: '1 43437U 18039A   20069.18626567 -.00000001  00000-0  17774-4 0  9992',
        SENTINEL_3B_TLE2: '2 43437  98.6261 137.2929 0001520 108.0064 252.1282 14.26735641 97440',

        HIMAWARI_7_TLE1: '1 28937U 06004A   20069.44561090 -.00000259  00000-0  00000-0 0  9992',
        HIMAWARI_7_TLE2: '2 28937   0.0687 298.1737 0001983  28.4063 146.4189  1.00274232 51520',

        HIMAWARI_8_TLE1: '1 40267U 14060A   20069.55952278 -.00000293  00000-0  00000-0 0  9995',
        HIMAWARI_8_TLE2: '2 40267   0.0358 135.3863 0000946 227.0731 147.2959  1.00269544 19791',

        HIMAWARI_9_TLE1: '1 41836U 16064A   20069.44473853 -.00000292  00000-0  00000-0 0  9998',
        HIMAWARI_9_TLE2: '2 41836   0.0204 172.9565 0001112 192.6801 102.8249  1.00270088 12338',

        NOAA_19_TLE1: '1 33591U 09005A   20069.56549379  .00000014  00000-0  32834-4 0  9999',
        NOAA_19_TLE2: '2 33591  99.1963  72.3111 0013952   0.1731 359.9443 14.12398055571038',

        NOAA_20_TLE1: '1 43013U 17073A   20069.40852524 -.00000017  00000-0  12707-4 0  9996',
        NOAA_20_TLE2: '2 43013  98.7484   8.8776 0000689  84.8834 275.2421 14.19541385119462',

        ISS_TLE1: '1 25544U 98067A   20069.90283561  .00000476  00000-0  16724-4 0  9992',
        ISS_TLE2: '2 25544  51.6431 119.7152 0005592   7.1652 165.0263 15.49228751216624',

        BLACKSKY_GLOBAL_1_TLE1: '1 43730U 18096M   22009.82268664  .00002974  00000-0  10612-3 0  9991',
        BLACKSKY_GLOBAL_1_TLE2: '2 43730  97.3723  90.9616 0016817 265.2961  94.6359 15.29554579173677',

        BLACKSKY_GLOBAL_2_TLE1: '1 43812U 18099BG  22010.14743595  .00000741  00000-0  71136-4 0  9992',
        BLACKSKY_GLOBAL_2_TLE2: '2 43812  97.6401  81.0085 0015113  81.4253 278.8680 14.95938660168985',

        BLACKSKY_GLOBAL_3_TLE1: '1 44367U 19037C   22009.84077916  .00006209  00000-0  14518-3 0  9998',
        BLACKSKY_GLOBAL_3_TLE2: '2 44367  45.0051 184.5048 0009045 304.4535  55.5499 15.43061136142658',

        BLACKSKY_GLOBAL_4_TLE1: '1 44499U 19054E   22010.11277299  .00001079  00000-0  72770-4 0  9996',
        BLACKSKY_GLOBAL_4_TLE2: '2 44499  45.0138 113.3689 0014168 163.7658 196.3677 15.13893962132361',

        BLACKSKY_GLOBAL_7_TLE1: '1 46088U 20055BP  22009.90663999  .00016089  00000-0  22786-3 0  9994',
        BLACKSKY_GLOBAL_7_TLE2: '2 46088  53.0054 285.9059 0002259  50.5328 309.5882 15.56676846 85680',

        BLACKSKY_GLOBAL_8_TLE1: '1 46089U 20055BQ  22009.92598641  .00015622  00000-0  21773-3 0  9995',
        BLACKSKY_GLOBAL_8_TLE2: '2 46089  53.0042 288.2079 0004778 272.7701  87.2762 15.57132218 85652',

        BLACKSKY_PATHFINDER_1_TLE1: '1 41787U 16059E   22010.12243504  .00000097  00000-0  27341-4 0  9991',
        BLACKSKY_PATHFINDER_1_TLE2: '2 41787  97.9379  59.9582 0029527 309.3116  50.5479 14.63884122282572',
    },
    SKYMAP50_PARAMETERS: {
        NEW_COLLECT: {
            maxArea: 300,
            minArea: 50,
            minWidth: 6,
            minHeight: 6,
            maxWidth: 23,
            maxHeight: 23,
        },
        ARCHIVAL: {
            maxArea: 300,
            minArea: 28,
            minWidth: 5,
            minHeight: 5,
            maxWidth: 23,
            maxHeight: 23,
        },
    },
    CGSTL_PARAMETERS: {
        // TODO: Double check these CGSTL Parameters
        // TODO: Receive these values from the backend instead of hard coding in client
        ARCHIVAL: {
            maxArea: 1000,
            minArea: 28,
            minWidth: 5,
            minHeight: 5,
            maxWidth: 40,
            maxHeight: 40,
        },
    },
    EMBEDDED_MAP: {
        HOST: 'https://embedded-map.soar-test.earth',
        TARGET_JS: 'soar_embed_2772022.js',
        TARGET_CSS: 'soar_embed_2772022.css',
        DEFAULT_WIDTH: '600px',
        DEFAULT_HEIGHT: '400px',
    },
};

let Constants;

if (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'prod') {
    Constants = { ...ProductionConstants, ...UniversalConstants };
} else {
    Constants = { ...DevelopmentConstants, ...UniversalConstants };
}

export default Constants;
